<template>
  <div class="duty-area flex-column">
    <div class="nameTit flex-space-between">
      <div class="tp-left flex-center-v">
        <span>责任区域</span>
        <el-button type="primary" size="mini" plain style="margin-left: 20px" @click="showFormTank" v-if="isProjectAdmin||isAdmin">新增责任区域</el-button>
      </div>
      <div class="tp-right flex-center-v">
        <el-input v-model="searchData.name" placeholder="责任区域名称" clearable size="small" style="margin-right:15px;"></el-input>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table ref="multipleTable" :data="tableList" height="100%" style="width: 100%" tooltip-effect="light myTooltips">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="name" label="责任区域名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="rectifyUserName" label="隐患整改人" align="center"></el-table-column>
        <el-table-column prop="noticeUserNameStr" label="隐患通知人" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reviewUserName" label="隐患复查人" align="center"></el-table-column>
        <el-table-column prop="subcontractUnitName" label="分包单位" align="center"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.status ? '启用中' : '作废中' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="isProjectAdmin||isAdmin">
          <template slot-scope="{ row }">
            <el-button type="text" size="mini" @click="edit(row)">编辑</el-button>
            <el-button v-if="!row.status" type="text" size="mini" @click="enableOrDisable(row, 'star')">启用</el-button>
            <el-button v-else type="text" size="mini" @click="enableOrDisable(row, 'end')">作废</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top: 10px">
      <el-pagination
          v-if="total > searchData.pageSize"
          background
          :current-page.sync="searchData.pageCurrent"
          :page-size="searchData.pageSize"
          :total="total"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="showTank" width="30%" :close-on-click-modal="false" @close="close">
      <el-form ref="form" :model="formData" :rules="rules" class="demo-form-inline" label-width="110px">
        <el-form-item label="责任区域名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入责任区域名称"></el-input>
        </el-form-item>
        <el-form-item label="隐患整改人" prop="rectifyUserId">
          <el-select v-model="formData.rectifyUserId" placeholder="请选择隐患整改人" style="width: 100%;">
            <el-option v-for="item in notifier" :key="item.id + 'aaa'" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="隐患通知人" prop="noticeUserIdStr">
          <el-select v-model="formData.noticeUserIdStr" multiple placeholder="请选择隐患通知人" style="width: 100%;">
            <el-option v-for="item in notifier" :key="item.id + 'bbb'" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="隐患复查人" prop="reviewUserId">
          <el-select v-model="formData.reviewUserId" placeholder="请选择隐患复查人" style="width: 100%;">
            <el-option v-for="item in notifier" :key="item.id + 'ccc'" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分包单位" prop="subcontractUnitId">
          <el-select v-model="formData.subcontractUnitId" placeholder="请选择分包单位" style="width: 100%;">
            <el-option v-for="item in areaList" :key="item.id + 'ddd'" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="责任区域说明" prop="explains">-->
<!--          <el-input type="textarea" placeholder="请输入责任区域说明" v-model="formData.explains" :rows="4" maxlength="300" show-word-limit></el-input>-->
<!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="save">确 定</el-button>
        <el-button size="small" @click="showTank = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apis from './apis'
import {mapGetters} from "vuex";

export default {
  name: "duty-area",

  components: {},

  props: {},

  data() {
    return {
      title: '新增责任区域',
      showTank: false,
      formData: {
        noticeUserIdStr: []
      },
      rules: {
        name: [{ required: true, message: '请输入责任区域名称', trigger: 'blur' }],
        rectifyUserId: [{ required: false, message: '请选择隐患整改人', trigger: 'change' }],
        noticeUserIdStr: [{ required: false, message: '请选择隐患通知人', trigger: ['change', 'blur'] }],
        reviewUserId: [{ required: false, message: '请选择隐患复查人', trigger: 'change' }],
        subcontractUnitId: [{ required: false, message: '请选择分包单位', trigger: 'change' }],
      },
      tableList: [],
      searchData: {
        pageCurrent: 1,
        pageSize: 10
      },
      total: 0,
      notifier: [],
      areaList: []
    }
  },

  computed: {
    ...mapGetters(['companyId','optionItem']),
    isProjectAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("项目管理员");
    },
    isAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("超级管理员")
    },
  },

  watch: {
    companyId() {
      this.search()
      this.getOptionList()
    }
  },

  mounted() {
    if(this.companyId) {
      this.getTableList()
      this.getOptionList()
    }
  },

  methods: {
    async getTableList() {
      this.searchData.departmentCode = this.companyId
      const result = await apis.pageDutyArea(this.searchData)
      this.tableList = result.list
      this.total = result.total
    },
    async getOptionList(data) {
      if (data){
        this.areaList = await apis.allSubcontractUnit(data)
      }else{
        this.areaList = await apis.allSubcontractUnit(this.companyId)
      }
    },
    showFormTank() {
      this.title = '新增责任区域'
      this.formData.departmentCode = this.companyId
      this.getNotifY().async
      this.isEdit = false
      this.showTank = true
    },
    close() {
      this.$refs.form.resetFields()
      this.getOptionList()
      this.formData = {}
    },
    async getNotifY(id){
      console.log(id,'id')
      if (id){
        this.notifier = await apis.findByDepartmentId(id)
      }else{
        this.notifier = await apis.findByDepartmentId(this.companyId)
      }
    },
    save() {
      this.$refs.form.validate(async validate => {
        if (validate) {
          const data = JSON.parse(JSON.stringify(this.formData))
          if (data.noticeUserIdStr) data.noticeUserIdStr = data.noticeUserIdStr.join(',')
          if(this.isEdit) await apis.editDutyArea(data)
          else await apis.addDutyArea(data)
          this.$message.success('操作成功')
          this.showTank = false
          this.getTableList().async
        }
      })
    },
    add() {
      this.title = '新增责任区域'
      this.isEdit = false
      this.showTank = true
    },
    edit(data) {
      this.title = '编辑责任区域'
      apis.DutyAreaFindById(data.id).then(res => {
        if (res.noticeUserIdStr) {
          let arr = res.noticeUserIdStr.split(',')
          let newArr = arr.map(item => Number(item))
          res.noticeUserIdStr = newArr
        }
        this.formData = JSON.parse(JSON.stringify(res))
        this.getNotifY(this.formData.departmentCode).async
        this.getOptionList(this.formData.departmentCode).async
        this.isEdit = true
        this.showTank = true
      })
    },
    enableOrDisable(data, status) {
      const title = status == 'star' ? '是否启用该责任区域' : '是否作废该责任区域'
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (status == 'star') await apis.enableDutyArea(data.id)
        else await apis.disableDutyArea(data.id)
        this.$message.success('操作成功')
        // this.init().async
        this.getTableList().async
      })
    },
    // 查询
    search() {
      this.searchData.pageCurrent = 1
      this.getTableList().async
    },
    // 切换页码
    async handleSizeChangeData(val) {
      this.searchData.pageCurrent = val
      this.getTableList().async
    }
  }
}
</script>

<style lang="stylus" scoped>
.duty-area {
  height 100%
  padding 10px
}
</style>
